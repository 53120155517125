<template>
    <div class="hotel-feed-card">
        <a href="#" class="hotel-feed-card__top" @click="setAssociateIdEvent">
            <img src="@/assets/icons/vuesax/linear/arrow-left.svg" alt="" width="15" height="15">

            {{ chat.associate.name }}
        </a>

        <div class="hotel-feed-card__messages-list">
            <div v-for="message in chat.messages" :key="message.messageId">
                <div class="hotel-feed-card__top-message" v-if="message.associateId == client.id">
                    <div class="hotel-feed-card__user-message">
                        <img class="hotel-feed-card__user-image" :src="`${filesPath}${message.associateImagePath}`"
                            v-if="message.associateImagePath" />
                        <img class="hotel-feed-card__user-image" src="@/assets/images/user-profile.svg" v-else />

                        {{ message.associateName }}
                    </div>

                    <div class="hotel-feed-card__message-date">
                        {{ message.messageDate }}
                    </div>
                </div>

                <div v-else class="hotel-feed-card__top-message">
                    <div class="hotel-feed-card__user-message">
                        <img class="hotel-feed-card__user-image" :src="`${filesPath}${message.associateImagePath}`" />
                        {{ message.associateName }}
                    </div>

                    <div class="hotel-feed-card__message-date">
                        {{ message.messageDate }}
                    </div>
                </div>

                <audio v-if="message.media.audio.messageVoiceNotePath" class="hotel-feed-card__message-audio" controls>
                    <source :src="`${filesPath}${message.media.audio.messageVoiceNotePath}`" />
                </audio>

                <a :href="`${filesPath}${message.media.images.messageImagePath}`" target="_blank"
                    v-if="message.media.images.messageImagePath">
                    <img :src="`${filesPath}${message.media.images.messageImagePath}`"
                        class="hotel-feed-card__message-image">
                </a>

                <p class="hotel-feed-card__message">
                    {{ message.messageText }}
                </p>
            </div>
        </div>

        <InputSendMessage :associate-id="associateId" :chat-id="chat.chatId" @sendedMessage="messageSended" />
    </div>
</template>

<script setup>
import { onMounted, nextTick, onUnmounted } from 'vue'
import { mainServices } from '../Services/MainServices.js'
import { chatModel } from '../Models/ChatModel.js'
import { useClientStore } from '../../../stores/ClientStore.js'
import InputSendMessage from './InputSendMessage.vue'

const props = defineProps(['associateId'])
const { getChatMessagesService } = mainServices()
const filesPath = process.env.VUE_APP_FILES_PATH
const emits = defineEmits(['setAssociateId'])
const chat = chatModel()
const { client } = useClientStore()

onMounted(() => {
    // * Get chat info
    getChat()

    // * Join into channel events socket
    joinChannelEventsChat()
})

onUnmounted(() => {
    Echo.leave(`associates-chat.${client.id}`)
})

async function getChat() {
    const response = await getChatMessagesService(props.associateId)

    // return console.log(response)
    if (response.hasErrors) return console.error(`Error getting chat: ${response.message}`)

    chat.chatId = response.data.chat_id
    chat.associate.name = response.data.associate.associateName
    chat.associate.imagePath = response.data.associate.associateImagePath
    chat.messages = response.data.messages.data.reverse()

    // * Await to load the new elements of messages 
    await nextTick()

    // * Update scroll position
    updateScrollList(false)
}

// * Subscribe to private channel chat
function joinChannelEventsChat() {
    Echo.private(`associates-chat.${client.id}`)
        .listen(".associates-chat-messages", async (e) => {
            // * Add new message into array
            if (e.associateId == props.associateId) chat.messages.push(e)

            // * Await to load the new elements of messages 
            await nextTick()

            // * Update scroll position
            updateScrollList(true)
        })
}

// * Emit event to close chat
function setAssociateIdEvent(associateId) {
    emits('setAssociateId', null)
}

// * Update scroll on locations list
function updateScrollList(animation) {
    // * Set scroll to bottom position
    const scroll = document.querySelector(".hotel-feed-card__messages-list")
    scroll.scroll({ top: scroll.scrollHeight, behavior: animation ? "smooth" : "auto" })
}

// * Add new sended message
async function messageSended(message) {
    chat.messages.push(message)

    await nextTick()

    updateScrollList(true)
}
</script>